import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { filestatus } from "../../api";

import globals from '../../globals';

import styles from "./Landing.module.css";

function randomNumberInRange(min: number, max: number) {
  return Math.round(min + Math.random() * (max - min));
}

const Landing = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [output, setOutput] = useState<JSX.Element | string>("Verifing PDF");
  const [dotLayout, setDotLayout] = useState("");
  const [uploadProcess, setUploadProcess] = useState({
    runDotInterval: false,
    runProcessInterval: false,
    stopRunProcessInterval: false
  })

  const [nowPercentage, setNowPercentage] = useState(0);
  const [progressBarColor, setProgressBarColor] = useState("");

  const totalPercentage = randomNumberInRange(50, 85);
  const processSecond = randomNumberInRange(5, 10) * 1000;

  const innerPdfID = searchParams.get("pdfID");

  //http://localhost:5173/#/?pdfID=asdf
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(innerPdfID);
    if (innerPdfID == null) {
      navigate(`/faq`);
    }
  }, [innerPdfID, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { runDotInterval, runProcessInterval, stopRunProcessInterval } = uploadProcess;
      if (runProcessInterval) {
        if (nowPercentage < totalPercentage) {
          setNowPercentage(nowPercentage + randomNumberInRange(1, 2));
        } else {
          setUploadProcess({
            runDotInterval,
            stopRunProcessInterval,
            runProcessInterval: false
          })
        }
      }
      if (stopRunProcessInterval) {
        clearInterval(interval);
      }
    }, 100);
    return function () {
      clearInterval(interval);
    }
  }, [totalPercentage, processSecond, nowPercentage, uploadProcess]);

  // loading dot message '...'
  useEffect(() => {
    const interval = setInterval(() => {
      const { runDotInterval } = uploadProcess
      if (runDotInterval) {
        setDotLayout(dotLayout.length < 3 ? dotLayout + "." : '')
      }
    }, 500);
    return function () {
      clearInterval(interval);
    }
  }, [dotLayout, uploadProcess]);

  const stopUploadProcess = () => setUploadProcess({
    runDotInterval: false,
    runProcessInterval: false,
    stopRunProcessInterval: true
  })

  const goFAQ = () => navigate(`/faq`);

  const getFileStatus = (intervalId: number) => {
    filestatus(innerPdfID)
      .then(result => {
        clearInterval(intervalId);
        let output: JSX.Element | string = '';
        let dotLayout = '';
        let progressBarColor = "";

        switch (result.status) {
          case "Completed": {

            stopUploadProcess()
            setNowPercentage(100);

            output = "You can chat with your PDF now.";
            dotLayout = "";

            globals.setPdfID(innerPdfID);
            globals.setEmail(result?.user_email);
            globals.setFileName(result?.fileset_detail?.[0]?.file_name);
            globals.setFilePageCount(result?.fileset_detail?.[0]?.file_page_count);
            globals.setFileSize(result?.fileset_detail?.[0]?.file_size);

            setTimeout(() => {
              navigate(`/askPDF`);
            }, 2000);

            break;
          }
          case "Active": {
            if (result.statuslog != null) {
              output = result.statuslog
            }
            break;
          }
          case "Failed": {
            if (result.statuslog != null) {
              output = result.statuslog;
            }

            stopUploadProcess()
            progressBarColor = "danger";

            globals.setFileName(result?.fileset_detail?.[0]?.file_name);
            globals.setFilePageCount(result?.fileset_detail?.[0]?.file_page_count);
            globals.setFileSize(result?.fileset_detail?.[0]?.file_size);
            break;
          }
          default: {
            stopUploadProcess()

            output = <p>Your file does not exist.<br />The file is unavailable due to an invalid URL or system cleanup. Please re-upload your PDF via the LF AI Assistant. Check our <a className={styles.a_color} onClick={goFAQ}>FAQ</a> for more details.</p>
            progressBarColor = "danger"
            dotLayout = ""
            break;
          }
        }

        setOutput(output)
        setDotLayout(dotLayout)
        setProgressBarColor(progressBarColor)
      });
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {
      getFileStatus(intervalId);
    }, 2000);
    setUploadProcess({
      runDotInterval: uploadProcess.runDotInterval,
      stopRunProcessInterval: uploadProcess.stopRunProcessInterval,
      runProcessInterval: true
    })

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className={styles.landing_mt}></div>
      <div className='d-flex justify-content-center'>
        <div className="col-6">
          <ProgressBar now={nowPercentage} label={`${nowPercentage}%`} variant={`${progressBarColor}`} />
          <div className="row">
            <div className='col-12 mt-12' id={styles.output_box}>{output}{dotLayout}</div>
          </div>
          {/* <div className='mt-4'>{processSecond}/{nowPercentage}/{totalPercentage}</div> */}

        </div>
      </div>
    </div>
  );
};

export default Landing;