import { useParams, useLocation, useSearchParams } from 'react-router-dom';

const Page2 = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();


  const { paramValue1 } = location.state || {};
  const { paramValue2 } = location.state || {};

  const { paramValue3 } = useParams();
  const { paramValue4 } = useParams();

  const userId = searchParams.get("userID");

  const bankAccounts = [
    { accountNumber: '1234567890', balance: 1000 },
    { accountNumber: '0987654321', balance: 2500 },
    { accountNumber: '5678901234', balance: 500 },
  ];

  return (
    <div>
      <p>Param by State: {paramValue1} {paramValue2}</p>
      <p>Param by Url: {paramValue3} {paramValue4}</p>
      <p>Param by ?userID: {userId}</p>
      <h1>Bank Accounts</h1>
      <ul>
        {bankAccounts.map((account, index) => (
          <li key={index}>
            Account Number: {account.accountNumber} | Balance: ${account.balance}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Page2;