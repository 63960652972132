import { useState, useMemo } from "react";

export default function useDisclosure({ initialOpen, onClose, onOpen }: { initialOpen?: boolean; onClose?: () => void; onOpen?: () => void } = {}) {
    const [isOpen, setIsOpen] = useState(initialOpen);

    return useMemo(
        () => ({
            isOpen,
            onClose: () => {
                onClose?.();
                setIsOpen(false);
            },
            onOpen: () => {
                onOpen?.();
                setIsOpen(true);
            },
            onToggle: () => {
                setIsOpen(!isOpen);
            }
        }),
        [isOpen, onClose, onOpen]
    );
}
