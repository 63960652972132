import { NullableType } from "./types/common";

const globals = {
    pdfID: "",
    fileName: "",
    filePageCount: 0,
    fileSize: 0,
    email: "",

    setPdfID: function (value: NullableType<string>) {
        this.pdfID = value || "";
    },
    setFileName: function (value: NullableType<string>) {
        this.fileName = value || "";
    },
    setFilePageCount: function (value: NullableType<number>) {
        this.filePageCount = value || 0;
    },
    setFileSize: function (value: NullableType<number>) {
        this.fileSize = value || 0;
    },
    setEmail: function (value: NullableType<string>) {
        this.email = value || "";
    }
};
export default globals;
