import React from 'react';

import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel
} from "@fluentui/react-components";

import styles from "./Faq.module.css";

const faqContent = [
  {
    value: 'faq1',
    header: 'What is LF AskPDF?',
    panel: <p>
      LF AskPDF is like a smart assistant for your PDFs. It's a smart search engine powered by artificial intelligence which sift through texts in your PDFs, and then answer questions about the content using natural language. The PDF can be all sorts of documents like vendor manuals, product specs, financial reports, and even legal contracts. All PDFs you upload are stored on the LF cloud storage and will not leak outside organization.
    </p>
  },
  {
    value: 'faq2',
    header: ' How do I use LF AskPDF?',
    panel: <p>
      To get started with LF AskPDF, open the LF AI Assistant on Outlook and pick a PDF to upload (just remember, it can't be more than 50 pages). Then just sit back and relax while it processes. Once it's done, you'll get an email with a link. You can click on this link when you want to ask any questions related to your PDF.
    </p>
  },
  {
    value: 'faq3',
    header: 'How does LF AskPDF work?',
    panel: <p>
      When you use AskPDF, it extracts and indexes text fragments from the PDF. When you ask a question, system finds top matches in semantic sense and feed those text fragments into ChatGPT, which then create a human like answer. Just so you know, you can also check the source of any answer by clicking on its citation.
    </p>
  },
  {
    value: 'faq4',
    header: 'Can I keep the file link for the future? How long can I use it?',
    panel: <p>
      The system retains up to 50 most recently uploaded PDF files among all users. If a large number of new files are uploaded, yours might get removed fairly quickly. You can access your uploaded file anytime, as long as it hasn't been removed. If you can't access your file link, just upload the PDF file again.
    </p>
  },
  {
    value: 'faq5',
    header: 'What can I do if I fail to access the file link from email?',
    panel: <p>
      If you are unable to access the file link from email, it may be expired due to PDF files reaching the system limit and your PDF file being removed by system housekeeping. If this happens, simply upload your PDF on the LF AI Assistant again.
    </p>
  },
  {
    value: 'faq6',
    header: 'Can I share the link with my colleague?',
    panel: <p>
      Absolutely! Anyone can use the active file link. So, if the PDF isn't confidential, feel free to share. If it is, then remember to keep it safe.
    </p>
  },
  {
    value: 'faq7',
    header: 'Does LF AskPDF understand images and tables in PDFs?',
    panel: <p>
      LF AskPDF cannot yet read images in the PDF, but it can read images that consist of scanned text, including scanned document. LF AskPDF can read text in tables, but it might have problems correlating the correct rows and columns.
    </p>
  },
  {
    value: 'faq8',
    header: 'What if I have feedback or suggestions for LF AskPDF?',
    panel: <p>
      Please send an email to <a href="mailto:LFAICopilot@lifung.com">LFAICopilot@lifung.com</a> with your thoughts.
    </p>
  }
]

const FAQ = () => {

  return (
    <div className='container'>
      <div className={`${styles.layer}`}>
        <h1>FAQ</h1>
        <Accordion multiple collapsible>
          {faqContent.map(content => (
            <AccordionItem key={content.value} value={content.value}>
              <AccordionHeader as="h3">{content.header}</AccordionHeader>
              <AccordionPanel>{content.panel}</AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <div className={` ${styles.m_bottom}`}></div>
    </div>
  );
};

export default FAQ;