import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";
import 'bootstrap/dist/css/bootstrap.css';


import FAQ from "./pages/faq/Faq";
import Landing from "./pages/landing/Landing";
import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Page1 from "./pages/page1/Page1";
import Page2 from "./pages/page2/Page2";
import FileUpload from "./pages/fileUpload/FileUpload";
import AskPDF from "./pages/askPDF/AskPDF";

initializeIcons();

export default function App() {

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Landing />} />
                    <Route path="faq" element={<FAQ />} />
                    <Route path="askPDF" element={<AskPDF />} />
                    <Route path="oneShot" element={<OneShot />} />
                    <Route path="new/page1" element={<Page1 />} />
                    <Route path="old/page2/:paramValue3?/:paramValue4?" element={<Page2 />} />
                    <Route path="fileUpload" element={<FileUpload />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

// eslint-disable-next-line unicorn/prefer-query-selector
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
