import { useState } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";

import { NullableType } from "../../types/common";
import styles from "./Layout.module.css";
import "./Layout.module.css";


const Layout = () => {
    const [subHeader, setSubHeader] = useState<NullableType<string>>();

    return (
        <div>
            <header className={styles.header} role={"banner"}>
                <div className={`container d-flex justify-content-center ${styles.headerContainer}`}>
                    <Link to="/" className={`me-auto ${styles.headerTitleContainer} d-flex`}>
                        <div className={styles.headerTitle}>LF AskPDF</div>
                        {/* {subHeader != null && subHeader != '' &&
                            <div className={`d-flex`}>
                                <div className={`${styles.centerLineContainer}`}>
                                    <div className={`${styles.centerLine}`}></div>
                                </div>
                                <div className={`d-flex align-items-center ${styles.headerSubTitle}`}>{subHeader}</div>
                            </div>
                        } */}
                    </Link>
                    <nav className="me-auto position-absolute">
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink to="askPDF" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    AskPDF
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="faq" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    FAQ
                                </NavLink>
                            </li>
                            {/* <li className={styles.headerNavLeftMargin}>
                                <NavLink to="oneShot" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Ask a question
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="new/page1" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Page 1
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="old/page2" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Page 2
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/fileUpload" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    File upload
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a href="https://aka.ms/entgptsearch" target={"_blank"} title="Github repository link">
                                    <img
                                        src={github}
                                        alt="Github logo"
                                        aria-label="Link to github repository"
                                        width="20px"
                                        height="20px"
                                        className={styles.githubLogo}
                                    />
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    <div className={styles.headerRightText}></div>
                </div>
            </header>

            <Outlet context={[subHeader, setSubHeader]} />
        </div>
    );
};

export default Layout;
