import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import cn from "classnames";
import styles from "./AskPDF.module.css";

import { chatApi, Indexes, Approaches, AskResponse, ChatRequest, ChatTurn, LoggingRequest, logging } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
// import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
// import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useNavigate } from 'react-router-dom';
import globals from '../../globals';
import imageAskPdf from "../../assets/image_ask_pdf.png";
import { NullableType, ActiveState } from "../../types";

const initActiveState = {
    citation: '',
    analysisPanelTab: null,
}

const AskPDF = () => {
    const navigate = useNavigate();
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState("");
    const [retrieveCount, setRetrieveCount] = useState(3);

    const [useSemanticRanker, setUseSemanticRanker] = useState(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState(false);
    const [excludeCategory, setExcludeCategory] = useState("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<NullableType<unknown>>();

    const [citationHeight, setCitationHeight] = useState("810px");

    const [activeState, setActiveState] = useState<ActiveState>(initActiveState)

    const [selectedAnswer, setSelectedAnswer] = useState(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const lastQuestionRef = useRef("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const pdfID = globals.pdfID;
    const email = globals.email;

    const hasPdf = Boolean(pdfID);

    const makeApiRequest = async (question: string) => {
        // eslint-disable-next-line no-console
        console.log("makeApiRequest");
        lastQuestionRef.current = question;

        error && setError(null);
        setIsLoading(true);
        setActiveState(initActiveState)

        uiTurning();

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                indexName: pdfID == null ? Indexes.MyIndex1 : pdfID,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatApi(request);
            const token_usage = result.token_usage;
            loggingAsk(question, token_usage);
            setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    function loggingAsk(question: string, token_usage: number) {
        const request: LoggingRequest = {
            email: email,
            functionGroup: "Ask",
            functionName: "Chat",
            userInput: question,
            token: token_usage,
            fileUID: pdfID,
        }
        logging(request);
    }

    function loggingCitation(citation: string) {
        const request: LoggingRequest = {
            email: email,
            functionGroup: "Ask",
            functionName: "Citation",
            userInput: citation.replace(/^.*[/\\]/, ''),
            token: 0,
            fileUID: pdfID,
        }
        logging(request);
    }

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(null);
        setActiveState(initActiveState)
        setAnswers([]);
        setTimeout(() => {
            uiTurning();
        }, 10);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    function uiTurning() {
        const askYourPDFLandingElement: HTMLDivElement | null = document.querySelector('.ask_your_pdf_landing');
        const pageInfoElement: HTMLDivElement | null = document.querySelector('.page_info');
        const chatContainerList: NodeListOf<HTMLDivElement> = document.querySelectorAll('.chat_container');
        const chatInputElement: HTMLDivElement | null = document.querySelector('.chat_input');
        const clearChatElement: HTMLDivElement | null = document.querySelector('.clear_chat');
        const tabListElement: HTMLDivElement | null = document.querySelector('#analysisContainer div[role="tablist"]');

        const navHeight = 36;
        const topPadding = 20;
        const bottomScroll = 6;

        const isChatBoxEmpty = [
            !lastQuestionRef.current,
            Boolean(askYourPDFLandingElement),
            Boolean(pageInfoElement),
            Boolean(chatInputElement),
            Boolean(clearChatElement)
        ]

        // it will run when we don't ask any questions
        if (isChatBoxEmpty.every(Boolean)) {
            const askYourPdfLandingHeight = askYourPDFLandingElement?.getBoundingClientRect().height || 0;
            const pageInfoHeight = pageInfoElement?.getBoundingClientRect().height || 0;
            const chatInputHeight = chatInputElement?.getBoundingClientRect().height || 0;
            const clearChatHeight = clearChatElement?.getBoundingClientRect().height || 0;

            if (askYourPDFLandingElement) {
                const marginTop = ((window.innerHeight / 2) - ((askYourPdfLandingHeight + pageInfoHeight + chatInputHeight + clearChatHeight) / 2) - (clearChatHeight + topPadding));
                askYourPDFLandingElement.style.marginTop = `${Math.max(marginTop, 50)}px`
            }
        }

        // when we ask some questions to bot
        if (lastQuestionRef.current && Boolean(clearChatElement)) {
            const clearChatHeight = clearChatElement?.getBoundingClientRect()?.height || 0;
            const tabListHeight = tabListElement?.getBoundingClientRect()?.height || 0;
            const chatMessageStream: HTMLDivElement | null = document.querySelector('.chat_message_stream');
            const analysisContainerElement: HTMLDivElement | null = document.querySelector('#analysisContainer');

            pageInfoElement?.classList?.add("d-none");

            if (tabListHeight) {
                setCitationHeight((window.innerHeight - clearChatHeight - navHeight - topPadding - tabListHeight - bottomScroll) + "px");
            }

            if (chatMessageStream) {
                chatMessageStream.style.maxHeight = window.innerWidth > 750 ? "none" : "720px";
            }

            const isChatContainerResized = Boolean((window.innerWidth > 750 && analysisContainerElement) || (window.innerWidth <= 750 && !analysisContainerElement));
            const addedClasses = isChatContainerResized ? ["col-md-6", "col-6"] : ["col-md-12", "col-12"];
            const removedClassed = isChatContainerResized ? ["col-md-12", "col-12"] : ["col-md-6", "col-6"];

            for (const container of chatContainerList) {
                if (container) {
                    container.classList.add(...addedClasses);
                    container.classList.remove(...removedClassed);

                    container.style.height = window.innerWidth <= 750 ? 'auto' : `${window.innerHeight - clearChatHeight - navHeight - topPadding}px`
                }
            }
        }
        // when we click clear chat
        else {
            // eslint-disable-next-line no-console
            console.log("E");
            pageInfoElement?.classList?.remove("d-none");
            for (const container of chatContainerList) {
                if (container) {
                    container.classList.add("col-md-12", "col-12");
                    container.style.height = "auto";
                }
            }
        }

    }
    useEffect(() => {
        uiTurning();

        window.addEventListener('resize', uiTurning)

        return () => {
            window.removeEventListener('resize', uiTurning)
        }
    }, []);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(Number.parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    // const onExampleClicked = (example: string) => {
    //     makeApiRequest(example);
    // };

    const onShowCitation = (citation: string, index: number) => {
        const { citation: activeCitation, analysisPanelTab } = activeState;
        const isSameCitation = activeCitation === citation && analysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index;
        const currentActiveState = { ...activeState };

        if (!isSameCitation) {
            currentActiveState.citation = citation;
            loggingCitation(citation);
        }

        currentActiveState.analysisPanelTab = isSameCitation ? null : AnalysisPanelTabs.CitationTab;
        setActiveState(currentActiveState)

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        setActiveState({
            ...activeState,
            analysisPanelTab: (activeState.analysisPanelTab === tab && selectedAnswer === index) ? null : tab
        })

        setSelectedAnswer(index);
    };

    const goFAQ = () => {
        navigate(`/faq`);
    };

    const currentAnalysisPanelTab = activeState.analysisPanelTab;

    return (
        <div className={`container ${styles.container}`}>
            <div className={`clear_chat ${styles.commandsContainer}`}>
                <ClearChatButton className={`${styles.commandButton}`} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                {/* <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} /> */}
            </div>
            <div className={`d-flex flex-wrap`}>
                <div className={`chat_container ${styles.chatContainer}`}>
                    {lastQuestionRef.current ? (
                        <div className={`chat_message_stream ${styles.chatMessageStream}`}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && Boolean(currentAnalysisPanelTab)}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    ) : (
                        <div className={styles.chatEmptyState}>
                            {/* <SparkleFilled fontSize={"120px"} primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Chat logo" /> */}
                            <div className="ask_your_pdf_landing">
                                <div className="d-flex justify-content-center"><img src={imageAskPdf} alt="Logo" onLoad={uiTurning} /></div>
                                <h1 className={`mt-3 mb-4 ${styles.chatEmptyStateTitle}`}>Ask your PDF</h1>
                            </div>
                            {/* <h2 className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</h2>
                            <ExampleList onExampleClicked={onExampleClicked} /> */}
                        </div>
                    )}

                    <div className={cn(`chat_input container d-flex justify-content-center ${styles.chatInput}`, {
                        'd-none': !hasPdf
                    })}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Ask any question about your PDF..."
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>

                    <div className="container d-flex justify-content-center col-12 mt-2 page_info">
                        {hasPdf ? (
                            <div>File: {globals.fileName} ({globals.filePageCount}  pages)</div>
                        ) : (
                            <div>The file is unavailable due to an invalid URL or system cleanup. Please re-upload your PDF via the LF AI Assistant. Check our <a className={styles.a_color} onClick={goFAQ}>FAQ</a> for more details.</div>
                        )}
                    </div>
                </div>

                {answers.length > 0 && currentAnalysisPanelTab && (
                    <AnalysisPanel
                        id='analysisContainer'
                        className={`chat_container ${styles.chatAnalysisPanel}`}
                        onLoad={uiTurning}
                        activeCitation={activeState.citation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight={citationHeight}
                        answer={answers[selectedAnswer][1]}
                        activeTab={currentAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
        </div>
    );
};

export default AskPDF;
