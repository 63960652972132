import { AskRequest, AskResponse, ChatRequest, FileStatusResponse, LoggingRequest } from "./models";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            fileset_unique_id: options.indexName,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw new Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch("/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            fileset_unique_id: options.indexName,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw new Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export function getCitationFilePath(pdfId: string, citation: string): string {
    return `/content/${pdfId}/${encodeURIComponent(citation)}`;
}

export async function filestatus(fileuid: string | null): Promise<FileStatusResponse> {
    const response = await fetch(`/filestatus?uniqueid=${fileuid}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "f9c9b26db58b42fe906226e4fb91f722"
        }
    });

    const parsedResponse: FileStatusResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw new Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function logging(options: LoggingRequest): Promise<void> {
    const data = {
        App_name: "LF AskPDF",
        App_group: "AI WebApps",
        App_ownerteam: "CropApps",
        App_deployment: "Prd",
        User_email: options.email,
        Function_group: options.functionGroup,
        Function_name: options.functionName,
        User_input: options.userInput,
        Log_tokenusage: options.token,
        Log_OtherInfo: options.fileUID
    };

    await fetch(`https://lfaihub.lfapps.net/api/util-Logging/v1`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1"
        },
        body: JSON.stringify(data)
    });
}
