/* eslint-disable @typescript-eslint/no-unused-vars */
import { useReducer, useRef, useState } from "react";
import { Checkbox, ChoiceGroup, IChoiceGroupOption, Panel, DefaultButton, Spinner, TextField, SpinButton } from "@fluentui/react";

import styles from "./OneShot.module.css";

import { askApi, Approaches, Indexes, AskResponse, AskRequest } from "../../api";
import type { NullableType } from '../../types/common'
import { Answer, AnswerError } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton/SettingsButton";
import useDisclosure from '../../hooks/useDisclosure';
import type { ActiveState } from '../../types';
import { initAnswerGeneration, answerGenerationreducer } from './answerGenerationReducer';

const initActiveState = {
    citation: '',
    analysisPanelTab: null,
}

const approaches: IChoiceGroupOption[] = [
    {
        key: Approaches.RetrieveThenRead,
        text: "Retrieve-Then-Read"
    },
    {
        key: Approaches.ReadRetrieveRead,
        text: "Read-Retrieve-Read"
    },
    {
        key: Approaches.ReadDecomposeAsk,
        text: "Read-Decompose-Ask"
    }
];

const indexNames: IChoiceGroupOption[] = [
    {
        key: Indexes.MyIndex1,
        text: "Use myindex1"
    },
    {
        key: Indexes.MyIndex,
        text: "Use myindex"
    },
    {
        key: Indexes.MyIndex2,
        text: "Use gptkbindex"
    }
];


const OneShot = () => {
    const lastQuestionRef = useRef("");

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<NullableType<unknown>>(null);
    const [answer, setAnswer] = useState<AskResponse>();

    const [activeState, setActiveState] = useState<ActiveState>(initActiveState);

    const { isOpen: isConfigPanelOpen, onClose: onCloseConfigPanel, onOpen: onOpenConfigPanel } = useDisclosure();

    const [state, dispatch] = useReducer(answerGenerationreducer, initAnswerGeneration);
    const { approach, indexName, promptTemplate, promptTemplatePrefix, promptTemplateSuffix, retrieveCount, excludeCategory, useSemanticRanker, useSemanticCaptions } = state;

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(null);
        setIsLoading(true);
        setActiveState(initActiveState);

        try {
            const request: AskRequest = {
                question,
                approach,
                indexName,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    promptTemplatePrefix: promptTemplatePrefix.length === 0 ? undefined : promptTemplatePrefix,
                    promptTemplateSuffix: promptTemplateSuffix.length === 0 ? undefined : promptTemplateSuffix,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions
                }
            };
            const result = await askApi(request);
            setAnswer(result);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch({ type: 'change_prompt_template', payload: newValue || "" })
    };

    const onPromptTemplatePrefixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch({ type: 'change_prompt_template_prefix', payload: newValue || "" })
    };

    const onPromptTemplateSuffixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch({ type: 'change_prompt_template_suffix', payload: newValue || '' })
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        dispatch({ type: 'change_retrieve_count', payload: newValue || '3' })
    };

    const onApproachChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        dispatch({ type: 'change_approach', payload: (option?.key as Approaches) || Approaches.RetrieveThenRead })
    };

    const onIndexChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        dispatch({ type: 'change_index_name', payload: (option?.key as Indexes) || Indexes.MyIndex1 })
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        dispatch({ type: 'change_semantic_ranker', payload: Boolean(checked) })
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        dispatch({ type: 'change_semantic_captions', payload: Boolean(checked) })
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        dispatch({ type: 'change_exclude_category', payload: newValue || '' })
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string) => {
        const { citation: activeCitation, analysisPanelTab } = activeState;
        const isSameCitation = activeCitation === citation && analysisPanelTab === AnalysisPanelTabs.CitationTab;
        const newActiveState = { ...activeState };
        if (!isSameCitation) {
            newActiveState.citation = citation;
        }

        newActiveState.analysisPanelTab = isSameCitation ? null : AnalysisPanelTabs.CitationTab;

        setActiveState(newActiveState)
    };

    const onToggleTab = (tab: AnalysisPanelTabs) => {
        setActiveState({
            ...activeState,
            analysisPanelTab: activeState.analysisPanelTab === tab ? null : tab
        })
    };

    return (
        <div className={styles.oneshotContainer}>
            <div className={styles.oneshotTopSection}>
                <SettingsButton className={styles.settingsButton} onClick={onOpenConfigPanel} />
                <h1 className={styles.oneshotTitle}>Ask your data</h1>
                <div className={styles.oneshotQuestionInput}>
                    <QuestionInput
                        placeholder="Example: Does my plan cover annual eye exams?"
                        disabled={isLoading}
                        onSend={question => makeApiRequest(question)}
                    />
                </div>
            </div>
            <div className={styles.oneshotBottomSection}>
                {isLoading && <Spinner label="Generating answer" />}
                {!lastQuestionRef.current && <ExampleList onExampleClicked={onExampleClicked} />}
                {!isLoading && answer && !error && (
                    <div className={styles.oneshotAnswerContainer}>
                        <Answer
                            answer={answer}
                            onCitationClicked={x => onShowCitation(x)}
                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab)}
                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab)}
                        />
                    </div>
                )}
                {error ? (
                    <div className={styles.oneshotAnswerContainer}>
                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                    </div>
                ) : null}
                {activeState.analysisPanelTab && answer && (
                    <AnalysisPanel
                        className={styles.oneshotAnalysisPanel}
                        activeCitation={activeState.citation}
                        onActiveTabChanged={x => onToggleTab(x)}
                        citationHeight="600px"
                        answer={answer}
                        activeTab={activeState.analysisPanelTab}
                    />
                )}
            </div>

            <Panel
                headerText="Configure answer generation"
                isOpen={isConfigPanelOpen}
                isBlocking={false}
                onDismiss={onCloseConfigPanel}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={() => <DefaultButton onClick={onCloseConfigPanel}>Close</DefaultButton>}
                isFooterAtBottom
            >
                <ChoiceGroup
                    className={styles.oneshotSettingsSeparator}
                    label="Approach"
                    options={approaches}
                    defaultSelectedKey={approach}
                    onChange={onApproachChange}
                />

                <ChoiceGroup
                    className={styles.oneshotSettingsSeparator}
                    label="index"
                    options={indexNames}
                    defaultSelectedKey={indexName}
                    onChange={onIndexChange}
                />

                {(approach === Approaches.RetrieveThenRead || approach === Approaches.ReadDecomposeAsk) && (
                    <TextField
                        className={styles.oneshotSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />
                )}

                {approach === Approaches.ReadRetrieveRead && (
                    <>
                        <TextField
                            className={styles.oneshotSettingsSeparator}
                            defaultValue={promptTemplatePrefix}
                            label="Override prompt prefix template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplatePrefixChange}
                        />
                        <TextField
                            className={styles.oneshotSettingsSeparator}
                            defaultValue={promptTemplateSuffix}
                            label="Override prompt suffix template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplateSuffixChange}
                        />
                    </>
                )}

                <SpinButton
                    className={styles.oneshotSettingsSeparator}
                    label="Retrieve this many documents from search:"
                    min={1}
                    max={50}
                    defaultValue={retrieveCount.toString()}
                    onChange={onRetrieveCountChange}
                />
                <TextField className={styles.oneshotSettingsSeparator} defaultValue={excludeCategory} label="Exclude category" onChange={onExcludeCategoryChanged} />
                <Checkbox
                    className={styles.oneshotSettingsSeparator}
                    checked={useSemanticRanker}
                    label="Use semantic ranker for retrieval"
                    onChange={onUseSemanticRankerChange}
                />
                <Checkbox
                    className={styles.oneshotSettingsSeparator}
                    checked={useSemanticCaptions}
                    label="Use query-contextual summaries instead of whole documents"
                    onChange={onUseSemanticCaptionsChange}
                    disabled={!useSemanticRanker}
                />
            </Panel>
        </div>
    );
};

export default OneShot;
