import { Approaches, Indexes } from "../../api";

interface AnswerGenerationAction {
    type:
        | "change_approach"
        | "change_index_name"
        | "change_prompt_template"
        | "change_prompt_template_prefix"
        | "change_prompt_template_suffix"
        | "change_retrieve_count"
        | "change_exclude_category"
        | "change_semantic_ranker"
        | "change_semantic_captions";
    payload: any;
}

interface AnswerGenerationState {
    approach: Approaches;
    indexName: Indexes;
    promptTemplate: string;
    promptTemplatePrefix: string;
    promptTemplateSuffix: string;
    retrieveCount: number;
    excludeCategory: string;
    useSemanticRanker: boolean;
    useSemanticCaptions: boolean;
}

export const initAnswerGeneration: AnswerGenerationState = {
    approach: Approaches.RetrieveThenRead,
    indexName: Indexes.MyIndex1,
    promptTemplate: "",
    promptTemplatePrefix: "",
    promptTemplateSuffix: "",
    retrieveCount: 3,
    excludeCategory: "",
    useSemanticRanker: true,
    useSemanticCaptions: false
};

export function answerGenerationreducer(state: AnswerGenerationState, action: AnswerGenerationAction): AnswerGenerationState {
    switch (action.type) {
        case "change_approach": {
            return {
                ...state,
                approach: action.payload
            };
        }
        case "change_index_name": {
            return {
                ...state,
                indexName: action.payload
            };
        }
        case "change_prompt_template": {
            return {
                ...state,
                promptTemplate: action.payload
            };
        }

        case "change_prompt_template_prefix": {
            return {
                ...state,
                promptTemplatePrefix: action.payload
            };
        }

        case "change_prompt_template_suffix": {
            return {
                ...state,
                promptTemplateSuffix: action.payload
            };
        }

        case "change_retrieve_count": {
            return {
                ...state,
                retrieveCount: action.payload
            };
        }

        case "change_exclude_category": {
            return {
                ...state,
                excludeCategory: action.payload
            };
        }

        case "change_semantic_ranker": {
            return {
                ...state,
                useSemanticRanker: action.payload
            };
        }

        case "change_semantic_captions": {
            return {
                ...state,
                useSemanticCaptions: action.payload
            };
        }
    }
}
