import { useState } from "react";
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from "./FileUopload.module.css";
import './FileUopload.module.css';

const splitFile = (file: File, size = 500_000) => {
    const fileChunkList = [];
    let currentChunkIndex = 0;
    while (currentChunkIndex <= file.size) {
        const chunk = file.slice(currentChunkIndex, currentChunkIndex + size);
        fileChunkList.push({ chunk: chunk, })
        currentChunkIndex += size;
    }
    return fileChunkList;
}

const FileUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState<Array<FileObject>>([]);
    const [progress, setProgress] = useState(0);
    const [chunkList, setChunkList] = useState<{ chunk: Blob; }[]>([]);

    const BorderLinearProgress = withStyles((theme: Theme) =>
        createStyles({
            root: {
                height: 10,
                borderRadius: 5,
            },
            colorPrimary: {
                backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            },
            bar: {
                borderRadius: 5,
                backgroundColor: '#1a90ff',
            },
        }),
    )(LinearProgress);

    const handleChange = (files: FileObject[]) => {
        setSelectedFiles(files);

        setProgress(100);
    };

    const splitAction = () => {
        for (const file of selectedFiles) {
            setChunkList(splitFile(file.file));
        }
        // selectedFiles.forEach((file) => {
        //     setChunkList(splitFile(file.file));
        // });
    }


    return (

        <div>
            <div className="d-flex justify-content-center mt-4">
                <div className="col-6">
                    <div className="row">
                        <DropzoneAreaBase maxFileSize={10_000_000} acceptedFiles={['image/png, image/jpeg, image/jpg, application/pdf']} onAdd={(fileObjs) => handleChange(fileObjs)} fileObjects={[]} />
                    </div>
                    <div className="row">
                        <BorderLinearProgress className={`mt-2 ${styles.progress_height}`} variant="determinate" value={progress} />
                    </div>
                </div>
            </div>
            <div className="mt-4">
                {selectedFiles.map((file, index) => (
                    <div className="row d-flex justify-content-center" key={index}>
                        Selected file: {file.file.name} | Size: {file.file.size}
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-center mt-4">
                <button type="button" className="btn btn-primary" onClick={splitAction}>SplitFile</button>
            </div>
            <div className="mt-4">
                {chunkList.map((chunk, index) => (
                    <div className="row d-flex justify-content-center" key={index}>
                        Chunk size: {chunk.chunk.size}
                    </div>
                ))}
            </div>



        </div>


    );
};

export default FileUpload;
