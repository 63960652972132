import { useNavigate } from 'react-router-dom';

const Page1 = () => {

    const navigate = useNavigate();

    const redirectToPage2WithState = () => {
        const paramValue1 = '1234';
        const paramValue2 = '5678';
        navigate('/old/page2', { state: { paramValue1: paramValue1, paramValue2: paramValue2 } })
    };

    const redirectToPage2WithPath = () => {
        const paramValue3 = 'ABCD';
        const paramValue4 = 'EFGH';
        navigate(`/old/page2/${encodeURIComponent(paramValue3)}/${encodeURIComponent(paramValue4)}`);
    };

    return (
        <div>
            <div>
                <h1>Page 1</h1>
                <div>
                    <button onClick={redirectToPage2WithState}>Go to Page 2 with state</button>
                </div>
                <div>
                    <button onClick={redirectToPage2WithPath}>Go to Page 2 with path</button>
                </div>
            </div>

        </div>

    );
};

export default Page1;
