import type { NullableType } from "../types/common";

export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export const enum Indexes {
    MyIndex1 = "myindex1",
    MyIndex = "myindex",
    MyIndex2 = "gptkbindex"
}

export type AskRequestOverrides = Partial<{
    semanticRanker: boolean;
    semanticCaptions: boolean;
    excludeCategory: string;
    top: number;
    temperature: number;
    promptTemplate: string;
    promptTemplatePrefix: string;
    promptTemplateSuffix: string;
    suggestFollowupQuestions: boolean;
}>;

export type AskRequest = {
    question: string;
    approach: Approaches;
    indexName: Indexes;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: NullableType<string>;
    token_usage: number;
    data_points: string[];
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    indexName: Indexes | string;
    overrides?: AskRequestOverrides;
};

export type FileStatusResponse = {
    status: NullableType<string>;
    statuslog: NullableType<string>;
    url: NullableType<string>;
    user_email: NullableType<string>;
    error?: string;
    fileset_detail: filesetDetail[];
};

export type filesetDetail = {
    file_name: NullableType<string>;
    file_page_count: NullableType<number>;
    file_size: NullableType<number>;
};

export type LoggingRequest = {
    email: string;
    functionGroup: string;
    functionName: string;
    userInput: string;
    token: number;
    fileUID: string;
};
