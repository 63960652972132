import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import globals from '../../globals';

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
};

const checkPartExistedInCitations = (citations: string[], part: string) => {
    if (!citations.includes(part)) {
        citations.push(part);
    }

    const newCitationIndex = citations.includes(part) ? citations.indexOf(part) + 1 : citations.length

    return newCitationIndex
}

export function parseAnswerToHtml(answer: string, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];
    const followupQuestions: string[] = [];

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer = answer.replace(/<<([^>]+)>>/g, (match, content) => {
        followupQuestions.push(content);
        return "";
    });

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    // Split text contains square brackets
    const parts = parsedAnswer.split(/\[([^\]]+)]/g);

    console.log({ parts })

    let lastCitation = -1;

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            // check content in square brackets
            if (!part.trim().startsWith(globals.fileName.split(".")[0]) || part.trim().includes(' ') || part.includes(',')) {
                return "[" + part + "]";
            }
            part = part.trim();
            // index of current file
            const citationIndex: number = checkPartExistedInCitations(citations, part);
            // index of next file
            let citationIndex2: number = -1;

            let partNext = '';

            const fileNameReversed = [...part].reverse().join('');
            const fileType = [...fileNameReversed.split('.')[0]].reverse().join('');
            const fileNameReversedSplit = fileNameReversed.split('.');

            // remove filetype
            const fileNameExceptMimeType = fileNameReversedSplit.slice(1).join('');

            if (fileNameExceptMimeType.length) {
                const fileNameSplit = [...fileNameExceptMimeType].reverse().join('').split('-');
                // new page count
                const newPageCount = Number.parseInt(fileNameSplit[fileNameSplit.length - 1]) + 1;

                if (newPageCount <= Number.parseInt(`${globals.filePageCount}`)) {
                    fileNameSplit[fileNameSplit.length - 1] = `${newPageCount}`;

                    // name file for next page ex: {filename}-{pageCount + 1}.{fileType}
                    partNext = `${fileNameSplit.join('-')}.${fileType}`
                    citationIndex2 = checkPartExistedInCitations(citations, partNext)
                }
            }


            if (parts.length >= index + 2 && parts[index + 1].trim() === "and" && parts[index + 2] === partNext) {
                citationIndex2 = -1;
            }

            // Check if there are two consecutive pages close to each other
            if (index > 2 && parts[index - 1].trim().length === 0 && lastCitation === citationIndex) {
                lastCitation = citationIndex2 == -1 ? citationIndex : citationIndex2;

                let path2 = '';
                if (citationIndex2 != -1) {
                    path2 = getCitationFilePath(globals.pdfID, partNext);
                }

                return path2 ? renderToStaticMarkup(
                    <a className="supContainer" title={partNext} onClick={() => onCitationClicked(path2)}>
                        <sup>{citationIndex2}</sup>
                    </a>
                ) : '';
            } else {
                const path = citationIndex == -1 ? '' : getCitationFilePath(globals.pdfID, part);
                const path2 = citationIndex2 == -1 ? '' : getCitationFilePath(globals.pdfID, partNext);
                lastCitation = citationIndex2 == -1 ? citationIndex : citationIndex2;

                let citationRendered = '';


                if (path) {
                    citationRendered += renderToStaticMarkup(
                        <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                            <sup>{citationIndex}</sup>
                        </a>
                    );
                }

                if (path2) {
                    citationRendered += renderToStaticMarkup(
                        <a className="supContainer" title={partNext} onClick={() => onCitationClicked(path2)}>
                            <sup>{citationIndex2}</sup>
                        </a>
                    );
                }

                return citationRendered
            }
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations,
        followupQuestions
    };
}
